<template>
  <div>
    <!-- <div style="text-align: right;">
        <el-button type="primary" class="mb10" size="mini" @click="exportExcel">导出数据</el-button>
      </div> -->
    <div>
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            v-loading="loading"
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
          >
            <ElTableColumn label="核销时间" prop="create_time" />
            <ElTableColumn label="客户姓名" prop="customer_name" />
            <ElTableColumn label="门店" prop="shop_name" />
            <ElTableColumn label="护理师" prop="customer_nurse" />
            <ElTableColumn label="卡项名称" prop="goods_name" />
            <ElTableColumn label="医师一" prop="nurse_id_one" />
            <ElTableColumn label="医师二" prop="nurse_id_two" />
            <ElTableColumn label="服务次数" prop="service_num" />
            <ElTableColumn label="跟台护士一" prop="nurse_one" />
            <ElTableColumn label="跟台项目一" prop="nurse_one_project" />
            <ElTableColumn label="跟台护士二" prop="nurse_two" />
            <ElTableColumn label="跟台项目二" prop="nurse_one_two" />
            <ElTableColumn label="操作" fixed="right">
              <template slot-scope="{ row }">
                <span class="theme"  @click="tapDetail('销售记录详情',row.order_id)">查看</span>
              </template>
            </ElTableColumn>
          </el-table>
          <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>

import pagination from '@/components/Pagination'
import { OutpatientCareDetail, } from "@/api/statistics";
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class Search {
  staffName = ''; // 员工名称
  statistics = 0; // 统计范围
}
export default {
  name: 'OrderList',
  components: {
    pagination
  },
  data () {
    return {
      search: new Search(), // 检索条件
      page: new Page(), // 分页
      list: [], // 数据展示
      loading:false,
    }
  },

  mounted () {
    this.getList();
  },
  methods: {
    getList() {
      
      this.loading = true
      OutpatientCareDetail({...this.page,person_id:this.$route.query.person_id,type:this.$route.query.type,start_time:this.$route.query.start_time,end_time:this.$route.query.end_time}).then(res => {
        this.list = res.data.list;
        this.page.total = res.data.dataCount;
        this.loading = false
      });
    },
    // 导出
    exportExcel() {
      shopDkDetail({shop_id:this.$route.query.shop_id,source_id:this.$route.query.source_id,export:1}).then(res => {
        window.open(res.data.url);
      });
    },
     // 点击按钮
     tapDetail(type, id) {

      if(id<1){
        this.$message.error('数据内容丢失，无法跳转查看');
        return false;
      }
  
      switch (type) {
        case "销售出库单详情":
          this.$router.push("../order/orderDetail?id=" + id + "&type=order");
          break;
        case "销售记录详情":
          const queryD = { id: id, detail: this.detail, type: "thirdly" };
          this.$router.push({ name: "销售出库单详情", query: queryD });
          break;
        case "add":
          const query = { clienId: this.clienId, detail: this.detail };
          this.$router.push({ name: "创建订单", query });
          break;
        case "export":
          this.hint("导出选中列表", "export");
          break;
        case "lock":
          if (this.search.end_time) {
            this.hint("锁定选中条件的订单", "lock");
          } else {
            this.$message({
              message: "请选择锁定日期",
              type: "warning"
            });
          }
          break;
      }
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
